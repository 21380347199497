/* eslint-disable import/no-anonymous-default-export */
export default {
  "placeholder.username": "请输入账户",
  "placeholder.password": "请输入密码",
  "placeholder.captcha": "请输入验证码",
  "btn.signin": "登录",
  "title.login": "欢迎登录",
  "tip.login.success": "登录成功",
  "btn.forgotten.password": "忘记密码？",
  "captcha.title": "请<em>依次</em>点击：",
  "btn.ok": "确定",
  fields: {
    auth: "授权",
    cancel: "取消",
  },
  auth_page: {
    desc: "{label}将被授予以下权限：",
    authList: {
      info: "获取你的账号信息",
      data: "获取相关操作数据",
    },
    leanMore: "了解更多",
  },
  error_info: {
    UN_REGISTER_APP: "找不到该应用",
    NO_AUTH_CODE: "用户拒绝了授权",
    AUTH_BUT_NO_ACCOUNT: "找不到该第三方账号对应的PUDU账号，登录后关联",
    STATE_ERROR: "缺少STATE字段",
  },
  message: {
    no_dots: "验证码不能为空",
  },
};

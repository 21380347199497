import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import "./index.scss";
import router from "./router";
import intl from "react-intl-universal";
import { getCurrentLocale, getLocaleData } from "./i18n";

function initIntl() {
  const currentLocale = getCurrentLocale();
  intl.init({
    currentLocale,
    locales: getLocaleData(),
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

initIntl();

root.render(
  <React.StrictMode>
    <Toaster position="top-center" />
    <RouterProvider router={router} />
  </React.StrictMode>
);

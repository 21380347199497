import { Outlet } from "react-router-dom";
import styles from "./base.module.scss";
import { Suspense } from "react";

export default function Base() {
  return (
    <Suspense>
      <div className={styles.base}>
        <Outlet />
      </div>
    </Suspense>
  );
}

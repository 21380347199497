import { type FC } from "react";

interface ErrorMessageProps {
  message?: string;
  style?: React.CSSProperties;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message, style }) => {
  if (!message || !message.trim()) {
    return (
      <span
        style={{
          fontSize: "12px",
          width: "100%",
          marginTop: "-12px",
          pointerEvents: "none",
          ...style,
        }}
      >
        &nbsp;
      </span>
    );
  }

  return (
    <span
      style={{
        color: "red",
        fontSize: "12px",
        marginTop: "-12px",
        transform: "translateY(20px)",
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        overflowWrap: "break-word",
        textWrap: "nowrap",
        pointerEvents: "none",
        ...style,
      }}
      title={message}
    >
      {message}
    </span>
  );
};

export default ErrorMessage;

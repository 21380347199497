import { type FC, FormEventHandler, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { formatMessage } from "react-intl-universal";
import Input from "./input";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { fetchLoginUrl, loginWithCaptcha } from "../api/api";
import Container from "./container";
import "./account-login.css";
import ErrorMessage from "../widgets/error-message";
import useQuery from "../hooks/use-query";
import { Messenger } from "../common/messenger";
import { encrypt } from "../common/public-key";
import ClickCaptcha from "src/widgets/click-captcha";

export interface AccoutLoginProps {
  title: string;
}

const AccountLogin: FC<AccoutLoginProps> = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [captchVisible, setCaptchaVisible] = useState(false);
  const [loginUrl, setLoginUrl] = useState("");
  const [captchaUrl, setCaptchaUrl] = useState("");
  const accountRef = useRef<{ username: string; password: string }>({
    username: "",
    password: "",
  });

  const query = useQuery();
  const appId = query.get("appid") as string;
  const showfp = query.get("showfp");
  Messenger.getInstance().init(appId ?? "0");

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (evt) => {
    setError("");

    evt.preventDefault();
    const formData = new FormData(evt.target as HTMLFormElement);
    const data = Object.fromEntries(formData);
    console.log("form values: ", data);

    // @ts-ignore
    const { username, password }: { username: string; password: string } = data;
    accountRef.current.username = username;
    accountRef.current.password = password;

    const response = await fetchLoginUrl(username as string);
    const responseData = response.data;
    if (responseData.code !== 200) {
      setError(responseData.message);
      return;
    }

    const loginUrl = responseData.data.url;
    const captchaUrl = responseData.data.captcha_url;
    setLoginUrl(loginUrl);
    setCaptchaUrl(captchaUrl);

    // 显示click captcha 弹窗
    setCaptchaVisible(true);
  };

  async function handleConfirm(data: {
    dots: { x: number; y: number }[];
    timestamp: string;
    captchaId: string;
  }) {
    setCaptchaVisible(false);

    const captcha: string = data.dots
      .map((dot) => [dot.x, dot.y].join(","))
      .join(",");
    const username = accountRef.current.username;
    const password = encrypt(accountRef.current.password);

    const loginParams = {
      account: username,
      password,
      appid: appId,
      timestamp: data.timestamp,
      captcha_id: data.captchaId,
      captcha,
    };
    const loginResp = await loginWithCaptcha(loginUrl, loginParams);

    if (loginResp.code !== 200) {
      setError(loginResp.message);
      Messenger.getInstance().notifyLoginFailed(loginResp.message);
      return;
    }

    const token = loginResp.data.token;
    const _token = encodeURIComponent(token);
    const expires = +loginResp.data.expires;
    const path = `/success?appid=${appId}&token=${_token}&expires=${expires}`;
    navigate(path);

    Messenger.getInstance().notifyLoginSuccess(token, expires);
  }

  return (
    <>
      <Container title={title}>
        <form onSubmit={handleSubmit} className="pudu-account-login-form">
          <Input
            placeholder={formatMessage({ id: "placeholder.username" })}
            type="text"
            name="username"
            prefix={<UserOutlined />}
          />

          <Input
            placeholder={formatMessage({ id: "placeholder.password" })}
            type="password"
            name="password"
            prefix={<LockOutlined />}
          />

          <ErrorMessage message={error} />

          <button type="submit" className="pudu-account-submit form-item">
            {formatMessage({ id: "btn.signin" })}
          </button>
        </form>

        {showfp && (
          <button
            className="forget-passwrod-link"
            onClick={() => Messenger.getInstance().notifyPasswordForgetten()}
          >
            {formatMessage({ id: "btn.forgotten.password" })}
          </button>
        )}
      </Container>
      {captchVisible && (
        <ClickCaptcha
          captchaUrl={captchaUrl}
          maxDot={5}
          width={300}
          height={240}
          onClose={() => setCaptchaVisible(false)}
          onConfirm={(data) => handleConfirm(data)}
        />
      )}
    </>
  );
};

export default AccountLogin;
